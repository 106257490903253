<template>
  <div class="row">
    <div class="col-xl-12">
      <DxDataGrid
        id="gvInhabitants"
        ref="gvInhabitants"
        v-bind="options"
        dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
        :data-source="inhabitantsStore"
        @editor-preparing="onEditorPreparing($event)"
        @toolbar-preparing="onToolbarPreparing($event)"
        :remote-operations="false"
        :word-wrap-enabled="true"
      >
        <DxColumn data-field="isMale" caption="Пол" width="60px" edit-cell-template="isMaleEditTemplate" cell-template="isMaleTemplate">
          <DxLookup :data-source="isMaleArray" display-expr="name" value-expr="id" />
        </DxColumn>
        <DxColumn data-field="name" caption="ФИО">
          <DxRequiredRule />
        </DxColumn>
        <DxColumn data-field="birthDate" data-type="date" caption="Дата рождения" width="100px" />
        <DxColumn data-field="birthPlace" caption="Место рождения" />
        <DxColumn data-field="snils" caption="СНИЛС" width="100px" />
        <DxColumn data-field="inn" caption="ИНН" width="100px" />
        <DxColumn data-field="registrationAddress" caption="Адрес регистрации" edit-cell-template="btnSetAddress">
          <DxFormItem>
            <DxLabel location="top" text="Адрес регистрации" />
          </DxFormItem>
        </DxColumn>
        <DxColumn data-field="passportDivisionCode" caption="Код подразд." width="100px" />
        <DxColumn data-field="passportSerialNumber" caption="Номер паспорта" width="100px" />
        <DxColumn data-field="passportIssueDate" data-type="date" caption="Дата выдачи" width="100px" />
        <DxColumn data-field="passportIssuedBy" caption="Кем выдан" />
        <DxColumn data-field="email" caption="Email" cell-template="emailCellTemplate">
          <DxEmailRule />
        </DxColumn>
        <DxColumn data-field="phone" caption="Телефон" cell-template="phoneCellTemplate">
          <DxPatternRule :pattern="phonePattern" message="Необходимо ввести в корректном формате" :ignoreEmptyValue="true" />
        </DxColumn>
        <DxColumn
          data-field="isDied"
          caption="Умерший"
          width="100px"
          cell-template="isDiedTemplate"
          edit-cell-template="isDiedEditTemplate"
        />
        <DxColumn data-field="deathDate" caption="Умерший" width="100px" :visible="false" />

        <template #emailCellTemplate="{ data: cellInfo }">
          <div v-if="cellInfo.data.email">
            <a :href="'mailto:' + cellInfo.data.email">{{ cellInfo.data.email }}</a>
          </div>
        </template>
        <template #phoneCellTemplate="{ data: cellInfo }">
          <div v-html="phoneCellLink(cellInfo.data.phone)"></div>
        </template>

        <template #isMaleTemplate="{ data: cellInfo }">
          <span v-if="cellInfo.data.isMale">муж.</span>
          <span v-else>жен.</span>
        </template>

        <template #isMaleEditTemplate="{ data: cellInfo }">
          <div class="d-flex gap-2">
            <input
              class="form-check-input"
              type="radio"
              name="radio"
              id="rbMale"
              :value="true"
              v-model="cellInfo.data.isMale"
              @click="setIsMale(cellInfo, true)"
            />
            <label class="form-check-label align-middle" for="rbMale">муж.</label>
            <input
              class="form-check-input"
              type="radio"
              name="radio"
              id="rbFemale"
              :value="false"
              v-model="cellInfo.data.isMale"
              @click="setIsMale(cellInfo, false)"
            />
            <label class="form-check-label align-middle" for="rbFemale">жен.</label>
          </div>
        </template>

        <template #btnSetAddress="{ data: cellInfo }">
          <div class="d-flex">
            <DxTextBox
              :value="cellInfo.data.registrationAddress"
              width="100%"
              ref="tbRegistrationAddress"
              @value-changed="(e) => cellInfo.setValue(e.value)"
            />
            <DxButton text="Адрес совпадает с адресом ЛС" @click="setAddress(cellInfo)" class="ms-2" />
          </div>
        </template>

        <template #isDiedTemplate="{ data: cellInfo }">
          <DxCheckBox :value="cellInfo.data.isDied" :disabled="true" />
        </template>
        <template #isDiedEditTemplate="{ data: cellInfo }">
          <div class="d-flex gap-4">
            <DxCheckBox :value="cellInfo.data.isDied" @value-changed="(e) => isDiedChanged(cellInfo, e)" />
            <DxDateBox
              :value="cellInfo.data.deathDate"
              class="w-100"
              :show-clear-button="true"
              :use-mask-behavior="true"
              @value-changed="(e) => cellInfo.component.cellValue(cellInfo.row.rowIndex, 'deathDate', e.value)"
            />
          </div>
        </template>

        <DxEditing
          :allow-adding="hasRightAddEditInhabitant"
          :allow-updating="hasRightAddEditInhabitant"
          :allow-deleting="hasRightAddEditInhabitant"
          mode="popup"
        >
          <DxPopup height="auto" width="800" />
          <DxForm>
            <DxItem data-field="name" editorType="dxTextArea" :col-span="2" />
            <DxItem data-field="isMale" />
            <DxItem data-field="isDied" />
            <DxItem
              data-field="birthDate"
              :editor-options="{ showClearButton: true, useMaskBehavior: true, placeholder: 'введите или выберите' }"
            />
            <DxItem data-field="birthPlace" />
            <DxItem data-field="snils" />
            <DxItem data-field="inn" />
            <DxItem itemType="group" :col-span="2">
              <DxItem data-field="registrationAddress" />
            </DxItem>
            <DxItem data-field="passportSerialNumber" />
            <DxItem
              data-field="passportIssueDate"
              :editor-options="{ showClearButton: true, useMaskBehavior: true, placeholder: 'введите или выберите' }"
            />
            <DxItem data-field="passportIssuedBy" />
            <DxItem data-field="passportDivisionCode" />
            <DxItem data-field="email" />
            <DxItem data-field="phone" />
          </DxForm>
        </DxEditing>
        <DxFilterRow :visible="true" />
        <DxScrolling row-rendering-mode="virtual" />
        <DxPaging :page-size="20" />
        <DxPager
          :visible="true"
          :allowed-page-sizes="[5, 10, 20, 50]"
          :show-page-size-selector="true"
          :show-navigation-buttons="true"
          :show-info="true"
        />
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import { DxEmailRule, DxPatternRule } from "devextreme-vue/validator";
import { authComputed } from "@/state/helpers";
import phonePattern, { blink, phoneCellLink } from "@/helpers";

const isMaleArray = [
  { id: true, name: "муж." },
  { id: false, name: "жен." },
];

import { inject } from "vue";
import { DxPopup, DxForm, DxFormItem, DxLabel } from "devextreme-vue/data-grid";
import { DxItem } from "devextreme-vue/form";
import { confirm } from "devextreme/ui/dialog";
import createStoreExtend from "@/helpers/grid/store";
import settings from "@/helpers/grid/settings.js";

export default {
  name: "InhabitantsComponent",
  components: {
    DxForm,
    DxItem,
    DxPopup,
    DxFormItem,
    DxLabel,
    DxEmailRule,
    DxPatternRule,
  },
  props: {
    debtorId: {
      type: [Number, String],
    },

    debtorData: {
      type: Object,
    },
  },
  setup() {
    var setInhabitantsCount = inject("setInhabitantsCount");

    return {
      setInhabitantsCount,
    };
  },
  computed: {
    ...authComputed,
  },
  data() {
    return {
      isMaleArray,
      phonePattern,
      options: settings,
      currentCity: process.env.VUE_APP_CITY,
      phoneCellLink,
      btnAddressSameWithDebtorOptions: {
        text: "Адрес",
        onClick: (e) => {
          console.log(e);
        },
      },
      inhabitantsStore: createStoreExtend({
        key: "id",
        loadUrl: `${process.env.VUE_APP_URL}/api/devextreme/inhabitants/inhabitants-by-debtor`,
        insertUrl: `${process.env.VUE_APP_URL}/api/devextreme/inhabitants/create`,
        updateUrl: `${process.env.VUE_APP_URL}/api/devextreme/inhabitants/update`,
        deleteUrl: `${process.env.VUE_APP_URL}/api/devextreme/inhabitants/delete`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.data.debtorId = this.debtorId;
        },
        onLoaded: (array) => {
          this.setInhabitantsCount(array.length);
        },
      }),
    };
  },

  methods: {
    isDiedChanged(cellInfo, e) {
      const rowIndex = cellInfo.row.rowIndex;
      const component = cellInfo.component;
      if (e.value) {
        component.cellValue(rowIndex, "isDied", e.value);
        component.cellValue(rowIndex, "deathDate", new Date(2000, 0, 1));
      } else {
        let result = confirm(
          "<span class='fs-5 align-middle'>Вы уверены, что хотите снять отметку и удалить дату смерти?</span>",
          "Дата смерти"
        );
        result.then(async (dialogResult) => {
          if (dialogResult) {
            component.cellValue(rowIndex, "deathDate", null);
            component.cellValue(rowIndex, "isDied", false);
          } else {
            component.cellValue(rowIndex, "isDied", true);
          }
        });
      }
    },
    repaint() {
      this.$refs.gvInhabitants.instance.updateDimensions();
    },
    onEditorPreparing(e) {
      if (e.parentType == "dataRow" && e.dataField == "passportSerialNumber") {
        e.editorName = "dxTextBox";
        e.editorOptions.mask = "CCCC CCCCCC";
        e.editorOptions.maskChar = "‒";
        e.editorOptions.showClearButton = true;
        e.editorOptions.useMaskedValue = true;
      }
      if (e.parentType == "dataRow" && e.dataField == "passportIssuedBy") {
        e.editorName = "dxTextArea";
        e.editorOptions.height = "60px";
      }
      if (e.parentType == "dataRow" && e.dataField == "snils") {
        e.editorName = "dxTextBox";
        e.editorOptions.mask = "CCC-CCC-CCC CC";
        e.editorOptions.useMaskedValue = true;
        e.editorOptions.showClearButton = true;
      }

      if (e.parentType == "dataRow" && e.dataField == "email") {
        e.editorOptions.showClearButton = true;
      }
      if (e.parentType == "dataRow" && e.dataField == "phone") {
        e.editorName = "dxTextBox";
        e.editorOptions.mask = "(000) 000-00-00";
        e.editorOptions.useMaskedValue = false;
        e.editorOptions.showClearButton = true;
      }
      if (e.parentType == "dataRow" && e.dataField == "passportDivisionCode") {
        e.editorName = "dxTextBox";
        e.editorOptions.mask = "CCC-CCC";
        e.editorOptions.useMaskedValue = true;
        e.editorOptions.showClearButton = true;
      }
    },
    onToolbarPreparing(e) {
      let toolbarItems = e.toolbarOptions.items;
      if (this.hasRightAddEditInhabitant) {
        let addRowButton = toolbarItems.find((x) => x.name === "addRowButton");
        addRowButton.options.text = "Добавить жителя";
        addRowButton.showText = "always";
      }
    },

    setAddress(cellInfo) {
      cellInfo.component.cellValue(
        cellInfo.row.rowIndex,
        "registrationAddress",
        this.currentCity + ", " + this.debtorData.house.address + " " + "кв. " + this.debtorData.debtor.flatNumber
      );

      blink(this, 3, "tbRegistrationAddress");
    },
    setIsMale(cellInfo, value) {
      cellInfo.component.cellValue(cellInfo.row.rowIndex, "isMale", value);
    },
  },
};
</script>

<style></style>
